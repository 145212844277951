import React from "react";
import { toastr } from "react-redux-toastr";
import {Form} from "react-bootstrap"
import { facebookApiErrorHandler, facebookApiFetchWrapper } from "../../../utils/facebookApiUtils";

export class UploadFacebookImgForm extends React.Component {
    constructor(props) {
        super(props);
        this.uploadImgFile = this.uploadImgFile.bind(this);
        this.faceBookGraphUrl = `https://graph.facebook.com/v21.0/${props.acc}/adimages`;
        this.state = {
            imgToUpload: null,
            imgDisplay: this.props.imageUrl,
            imgValidity: false
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            imgDisplay: nextProps.imageUrl,
        });
    }

    /**
     * 
     * @param {*} file - image sfile
     * create bytes array from file. in UTF8 bytes array 
     * 
     */
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (el) => {
                this.setState({
                    imgToUpload: file,
                    imgDisplay: el.target ? el.target.result : "",
                    imgValidity: true
                })
                resolve(reader.result);
            };
        });
    }

    changeFile(file) {
        // File was changed and supplied
        if (file) {
            this.getBase64(file)
                .then(fileBytes => {
                    this.setState({ imgName: file.name });
                    this.uploadImg(fileBytes);
                });
        }
        // File was changed and prompt was closed without selecting a file
        else {
            this.setState({
                imgToUpload: null,
                imgDisplay: "",
                imgValidity: false
            });
        }
    }

    createFBchunkFormDataObject() {
        let formData = new FormData();
        formData.append("access_token", this.props.accessToken);
        return formData;
    }

    uploadImg(fileBytes) {
        let fileInBytes = fileBytes;
        let formData = this.createFBchunkFormDataObject();

        // remove file data from bytes string
        formData.append('bytes', fileInBytes.replace(/^data:image\/(png|jpe?g);base64,/, ''));
        formData.append('name', this.state.imgName);
        let payload = {
            method: "POST",
            body: formData
        };
        facebookApiFetchWrapper(this.faceBookGraphUrl, "VIDEO_UPLOAD_TRANSFERING", payload)()
            .then(result => {
                if (result.success) {
                    this.setState({ progress: null });
                    let images = result.data.images;
                    this.props.successCallback(images[this.state.imgName]);
                } else {
                    facebookApiErrorHandler(result);
                }
            });


    }

    uploadImgFile() {
        if (this.state.imgToUpload == null) {
            toastr.error("Please select an image to upload");
            this.setState({ ...this.state, imgValidity: false });
            return;
        } else {
            this.uploadImg();
        }
    }

    render() {
        return (
            <Form.Group>
                <Form.Label>Img File </Form.Label>
                <Form.Control type="file" accept="image/*"
                    onChange={(e) => { this.changeFile(e.target.files["0"]) }}
                />
            </Form.Group>
        )
    }

}