import React from "react";
import {
    Button,
    Card,
    Container,
    Form,
    FormGroup,
    Modal,
    Spinner,
    Table
} from "react-bootstrap";
import { genericFetcherFactory } from "../../../utils/requestUtils";
import { Label, Input, Row } from 'reactstrap';
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import AccountDropdown from "../../../components/AccountDropdown";
import { AccountIsActive } from "../../prods-and-accounts/account/accountUtils";
import GenericErrorAlert from "../../../components/GenericErrorAlert";
import Loader from '../../../components/Loader';

import FacebookContextComponent from "../../../components/FacebookContextComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

class AdSetExpansionTool extends React.PureComponent {
    constructor(props) {
        super(props);

        this.accountChanged = this.accountChanged.bind(this);
        this.duplicateAdsets = this.duplicateAdsets.bind(this);


        this.state = {
            selectedAccount: null,
            activeAccounts: [],
            adsetIds: [],
            showingSavePrompt: false,
            duplicateAdsets: []
        }
    }

    componentDidMount() {
        this.getActiveAccounts();
    }

    getActiveAccounts() {
        genericFetcherFactory(`/api/accounts/Accounts/activeAccountIdListFacebook`, "ACTIVE_ACCOUNTS", "Failed to fetch active accounts")().then(result => {
            if (result.success === true) {
                this.setState({ activeAccounts: result.data });
                return result.data;
            }
        });
    }

    accountChanged(account) {
        if (account !== undefined) {
            this.setState({
                selectedAccount: account,
                adsetIds: [],
                duplicateAdsets: []
            });
        }
    }

    duplicateAdsets(adsets) {
        this.setState({ showingSavePrompt: true });
        const adsetsList = adsets.split(",");

        genericFetcherFactory(`/api/facebook-interface/DuplicateAdSets/${this.state.selectedAccount.accountPlatformId}`, "DUPLICATE_ADSES", "Failed to duplicate", "POST", {
            method: "POST",
            body: JSON.stringify(adsetsList),
            headers: { "Content-Type": "application/json" }
        })().then((res) => {
            this.setState({ showingSavePrompt: false });

            if (res.success) {
                this.setState({
                    duplicateAdsets: res.data,
                    adsetIds: []
                });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    updateStateCallback(key, value) {
        this.setState({ [key]: value });
    }

    renderDuplicatedAdsetsTable() {
        let jsxDuplicateAdsets = (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th> AdSet ID</th>
                        <th>AdSet Name</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.duplicateAdsets.map(({ id, name }, index) => (
                        <tr key={index}>
                            <td>{id}</td>
                            <td>{name}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
        return jsxDuplicateAdsets;
    }

    render() {
        let duplicatedAdsetsTable = this.renderDuplicatedAdsetsTable();

        return (
            <Container fluid className="p-0">
                <Modal size="xxl" show={this.state.duplicateAdsets.length > 0 || this.state.showingSavePrompt === true}
                    centered
                >
                    <Modal.Header>Duplicated Ad Sets:</Modal.Header>
                    <Button color="secondary" size="small"
                        style={{ position: "absolute", top: "10px", right: "10px" }}
                        onClick={e => this.setState({ duplicateAdsets: [] })}>
                        Close
                    </Button>
                    <Modal.Body>
                        {this.state.showingSavePrompt === true &&
                            <Loader content={["D Du Dup Duplicating Ad Sets"]} />
                        }
                        {this.state.showingSavePrompt === false &&
                            this.state.duplicateAdsets &&
                            <>
                                {duplicatedAdsetsTable}
                            </>
                        }
                    </Modal.Body>
                </Modal>

                <Card>
                    <Card.Header className="pb-1">
                        <Card.Title tag="h5" className="mb-0">Expansion Tool</Card.Title>                
                        <Card.Text> <FontAwesomeIcon icon={faInfoCircle} /> This tool duplicates ad sets on Facebook
                        </Card.Text>      
                    </Card.Header>
                    <Card.Body>

                        {!this.props.facebookUserData.isLoggedIn ?
                            <Button color="info"><FacebookContextComponent shouldRender={true} /></Button>
                            :
                            <>
                                <div className="mb-4" style={{ display: "flex", justifyContent: "space-between", zIndex: 1, position: 'relative' }}>
                                    <AccountDropdown
                                        widthClass={"col-12 col-sm-4"}
                                        updateStateCallback={this.updateStateCallback}
                                        accountChanged={this.accountChanged}
                                        selectedAccount={this.state.selectedAccount}
                                        platform='FACEBOOK'
                                        itemActive={AccountIsActive.ACTIVE} />
                                </div>
                                {this.state.selectedAccount !== null &&
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <Label for="adsetsToDup">Ad Set IDs:</Label>
                                        <div style={{ width: "500px", display: "flex", alignItems: "center" }}>

                                            <Input
                                                type="text"
                                                name="adsetsToDup"
                                                id="adsetsToDup"
                                                value={this.state.adsetIds}
                                                placeholder="Enter one or more ad sets, comma-separated"
                                                onChange={(event) => {
                                                    let inputValue = event.target.value;
                                                    inputValue = inputValue.replace(/\s+/g, "");
                                                    this.setState({ adsetIds: inputValue });
                                                }}
                                            />
                                            <Button disabled={!/^(\d+)(,\d+)*$/.test(this.state.adsetIds)}
                                                onClick={() => { this.duplicateAdsets(this.state.adsetIds); }}>Duplicate
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}


export default connect((store) => {
    return {
        facebookUserData: store.userContext.facebookUserData
    }
})(AdSetExpansionTool)
