import React from "react";
import ImagePicker from 'react-image-picker';
import 'react-image-picker/dist/index.css';
import { facebookApiErrorHandler, facebookApiFetchWrapper } from "../utils/facebookApiUtils";
import GenericErrorAlert from "./GenericErrorAlert";
import Loader from "./Loader";

export class VideoThumbnails extends React.Component {
    constructor(props) {
        super(props);
        this.onPick = this.onPick.bind(this);
        this.faceBookGraphUrl = `https://graph-video.facebook.com/v21.0/${this.props.videoId}/thumbnails?access_token=${this.props.accessToken}`;
        this.state = {
            image: null,
            imageList: null,
            initSuccess: null,
            thumbnailsReady: null
        };
    }
    onPick(image) {
        this.setState({ image });
        this.props.selectedCallback(image);
    }

    async getThumbnailFromFacebook() {
        facebookApiFetchWrapper(this.faceBookGraphUrl, "Get Video ThumbNails", { method: "GET" })()
            .then(result => {
                if (result.success) {
                    if (result.data.data.length < 1) {
                        return new Promise(resolve => {
                            resolve(this.getThumbnailFromFacebook());
                        });
                    } else {
                        let imageList = result.data.data;
                        this.setState({ imageList: imageList, initSuccess: true });
                    }
                    return true;

                } else {
                    this.setState({ initSuccess: false });
                    facebookApiErrorHandler(result);
                    return false;
                }
            });
    }

    componentDidMount() {
        if (this.props.videoId !== null && this.state.thumbnailsReady === null) {
            return new Promise(resolve => {
                resolve(this.getThumbnailFromFacebook());
            });
        } else {
            this.setState({ initSuccess: false });
        }
    }

    render() {

        if (this.state.initSuccess === true) {
            return (
                <div class="form-group">
                    <ImagePicker
                        images={this.state.imageList.map((image) => ({ src: image.uri, value: image.id }))}
                        onPick={this.onPick}
                    />
                </div>

            );
        } else if (this.state.initSuccess === false) {
            return (<GenericErrorAlert />);

        } else {
            return (<Loader />);

        }
    }

}